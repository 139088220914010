import { StickyHeader } from './header'
import { CountDown } from './countDown'
import Simulator from './simulator'
import { tns } from 'tiny-slider'

// Sticky header
window.onscroll = () => StickyHeader('body > header')

// Init FAQ
const faqLinks = document.querySelectorAll('#faq article')

Array.from(faqLinks).forEach(link => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    let currentElt = event.target.parentElement
    if(currentElt.tagName != 'ARTICLE') currentElt = currentElt.parentElement
    Array.from(document.querySelectorAll('#faq article.expanded')).forEach(elt => {
      if (currentElt !== elt) elt.classList.remove('expanded')
    })
    currentElt.classList.toggle('expanded')
  })
})


// Initialize slider
const slider = document.querySelector('.slide')
if (slider) {
  const EdgePadding = (window.innerWidth - slider.offsetWidth) / 2
  const famersSwiper = tns({
    container: '.famersSwiper',
    autoWidth: true,
    items: 1,
    edgePadding: EdgePadding,
    startIndex: 1,
    controls: false,
    nav: true,
    navPosition: 'bottom',
    autoplay: true,
    autoplayButton: false
  })
}


// Coutdown
//const countDown = CountDown('02/23/2019 00:00:00 PM', '.countdown')
if(location.pathname.substr(-8) == '/success' || location.search == '?success=1'){
  let successMsg = document.createElement('div');
  successMsg.classList.add('banner');
  successMsg.innerHTML = "<strong>C’est tout bon, </strong>vous n’avez plus qu’à attendre notre appel&nbsp;!";
  let headerElt = document.getElementsByTagName('header')[0];
  headerElt.insertBefore(successMsg, headerElt.firstElementChild);
  document.getElementsByTagName('body')[0].style.paddingTop = '170px';
}

// Simulator
const simulator = new Simulator('#simulator')

const textForms = document.querySelectorAll('.text_form')

Array.from(textForms).forEach(link => {
  link.addEventListener('submit', (event) => {
    event.preventDefault()

    let formElt = event.target;
    if(formElt.parentElement.querySelector('span.error')){
      formElt.parentElement.querySelector('span.error').outerHTML = '';
    }

    let tel = formElt.querySelector('input').value.trim();
    const telRegex = /^(?:(?:\+|00)33|0)\s*[6-7](?:[\s.-]*\d{2}){4}$/;

    if(! telRegex.test(tel)){
      let errorMsg = document.createElement('span');
      errorMsg.classList.add('error');
      errorMsg.innerHTML = 'Numéro de tel non valide<br>';
      formElt.parentElement.insertBefore(errorMsg, formElt.parentElement.firstChild);
      return false;
    }

    formElt.querySelector('button').setAttribute('disabled', 'disabled');

    let fetchInit = {
      method: 'POST',
      mode: 'cors'
    };
    fetchInit.body = new FormData();
    fetchInit.body.append('tel', tel);

    fetch('/service/texttodownload', fetchInit)
      .then(response => {
        if (!response.ok) {
          return Promise.reject({
            status: response.status,
            message: "code: " + response.status
          });
        }
        return response.text();
      })
      .then(response => {
        formElt.innerHTML = '';
        let successMsg = document.createElement('div');
        successMsg.classList.add('confirm');
        successMsg.innerHTML = "C'est tout bon, vous allez recevoir un SMS au " + tel + " avec le lien pour télécharger gratuitement Perfarmer !";
        formElt.appendChild(successMsg);
      })
      .catch(error => {
        let errorMsg = document.createElement('span');
        errorMsg.classList.add('error');
        errorMsg.innerHTML = "Erreur lors de l'envoi du SMS (" + error.message + ")<br>";
        formElt.parentElement.insertBefore(errorMsg, formElt.parentElement.firstChild);
        formElt.querySelector('button').removeAttribute('disabled');
      });

    return false;
  })
});