const DATAS = {
  "ble_tendre": {
    "min": 150,
    "init": 250,
    "max": 420,
    "years": {
      "2019": 190,
      "2020": 231,
      "2021": 398,
      "2022": 398,
      "2023": 278
    }
  },
  "colza": {
    "min": 350,
    "init": 550,
    "max": 1050,
    "years": {
      "2019": 404,
      "2020": 583,
      "2021": 1035,
      "2022": 807,
      "2023": 541
    }
  },
  "mais": {
    "min": 150,
    "init": 250,
    "max": 380,
    "years": {
      "2019": 179,
      "2020": 251,
      "2021": 356,
      "2022": 331,
      "2023": 240
    }
  }
}

export default class Simulator {
  constructor(selector) {
    this.wrapper = document.querySelector(selector)

    if (!this.wrapper) return
    this.initialize()
  }

  initialize () {
    // this.button = this.wrapper.querySelector('.button')
    this.products = this.wrapper.querySelectorAll('.products li')
    this.input = this.wrapper.querySelector('input')
    this.value = this.wrapper.querySelector('.value')
    this.results = this.wrapper.querySelectorAll('.results dd')

    // this.button.addEventListener('click', (event) => this.updateResults(event))
    this.products.forEach((product) => {
      product.addEventListener('click', (event) => this.changeProduct(event))
    })
    this.input.addEventListener('input', () => this.updateValue())

    this.selected = 'ble_tendre'
    this.input.value = DATAS[this.selected].init

    this.updateValue()
    this.updateResults()
  }

  updateResults (event) {
    if (event) event.preventDefault()

    const years = DATAS[this.selected].years
    const value = this.input.value
    this.results.forEach((elt) => {
      const year = elt.attributes.id.value
      if (years[year] > value) {
        elt.classList.add('on')
      } else {
        elt.classList.remove('on')
      }
    })
  }

  updateValue () {
    this.value.innerHTML = this.input.value
    this.updateResults()
  }

  changeProduct (event) {
    if (event) event.preventDefault()
    this.selected = event.target.dataset.product
    this.wrapper.dataset.selected = this.selected

    // show selected
    this.wrapper.querySelectorAll('.products .active').forEach((elt) => elt.classList.remove('active'))
    event.target.classList.add('active')

    // udpate input range
    const datas = DATAS[this.selected]
    this.input.setAttribute('min', datas.min)
    this.input.setAttribute('max', datas.max)

    this.input.value = datas.init
    this.updateValue()
  }
}
